<template>
  <div>
    <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        type="button"
        @click="addPayment"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        {{ $t('forms.add_model', {model: $t('models.payment')})}}
      </b-button>
      <b-button
        variant="outline-secondary"
        type="button"
        @click="hasHistory() 
          ? $router.go(-1) 
          : $router.push({ 
          name: 'school-teachers-view', 
          params: {
                      id: school.id,
                      teacher_id: userData.id,
                    },
        })"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        {{ $t('forms.go_back') }}
      </b-button>
    <template v-if="show_form">
      <hr>
      <validation-observer
        ref="refFormObserver"
      >
        <!-- User Info: Input Fields -->
        <b-form
          style="margin:20px 0;"
        >
          <b-row>

            <!-- Field: Plan -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Payment Method"
                rules="required"
                :custom-messages="{ required: $t('validators.required') }"

              >
                <b-form-group
                  label="Payment Method / Partner"
                  label-for="plan"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="form_plan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="planOptions"
                    :reduce="val => val.value"
                    :selectable="val => val.can_select"
                    :clearable="false"
                    input-id="plan"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>


          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
            <!-- SUBSCRIPTION -->
              <b-form-group>
                <b-form-checkbox
                  v-model="form_subsSelected"
                  plain
                >
                  Free Subscription
                </b-form-checkbox>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
            <!-- SUBSCRIPTION Dates -->
              <template v-if="!form_subsSelected">
                <!-- valid from -->
              <validation-provider
                #default="validationContext"
                name="valid_from"
              >
              <b-form-group
                  label="Valid From"
                  label-for="valid_from"
                  :state="getValidationState(validationContext)"
                >
                <b-form-datepicker
                  id="valid_from"
                  v-model="form_valid_from"
                  class="mb-1"
                  start-weekday="1"
                  :locale="getLocal"
                />
              </b-form-group>
              </validation-provider>
              <!-- valid_until -->
              <validation-provider
                #default="validationContext"
                name="valid_until"
              >
              <b-form-group
                  label="Valid Until"
                  label-for="valid_until"
                  :state="getValidationState(validationContext)"
                >
                <b-form-datepicker
                  id="valid_until"
                  v-model="form_valid_until"
                  start-weekday="1"
                  class="mb-1"
                  :locale="getLocal"
                />
              </b-form-group>
              </validation-provider>
              </template>
            </b-col>

          </b-row>

        </b-form>
      </validation-observer>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        type="button"
        @click="submitChanges"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        {{ $t('forms.save_changes') }}
      </b-button>
      <b-button
        variant="outline-secondary"
        type="button"
        @click="closeForm"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        {{ $t('forms.cancel') }}
      </b-button>
    </template>
    <hr>

    <licence-view-payments-card 
      v-if="userData"
      :payments="userData.payments"
      :show_edit_column="true"
      :blocked_payment_methods="blocked_payment_methods"
      @set-payment="setPayment"
      />
  </div>
</template>

<script>
import { editLicence } from '@/api/routes'
import {
  BFormDatepicker, 
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import LicenceViewPaymentsCard from '../view/PaymentsCard.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormDatepicker,
    vSelect,

    LicenceViewPaymentsCard,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    school: {
      type: Object,
      required: true
    },
    userData: {
      type: Object,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    blocked_payment_methods: {
      type: Array,
      required: true,
    }
  },
  data(){
    return {
      required,
      alphaNum,
      password,

      show_form: false,
      form_id:null,
      form_plan: null,
      form_subsSelected: true,
      form_valid_from:null,
      form_valid_until: null,

    }
  },
  computed:{
    getLocal(){
      return this.$store.getters['user/getLang']
    },
  },
  methods: {
    hasHistory () { 
       return window.history.length > 2 
    },
    closeForm(){
      this.show_form = false;
      this.form_id = null;
      this.form_plan = null;
      this.form_subsSelected = true;
      this.form_valid_from = null;
      this.form_valid_until = null;
    },
    addPayment(){
      this.show_form = true;
      this.form_id = null;
      this.form_plan = null;
      this.form_subsSelected = true;
      this.form_valid_from = null;
      this.form_valid_until = null;
    },
    setPayment(payment_id){
      this.userData.payments.map( x => {
        if(x.id == payment_id){
          this.form_id = x.id;
          this.form_plan = x.payment_method_id;
          this.form_subsSelected = x.valid_until ? false : true;
          this.form_valid_from = x.valid_from;
          this.form_valid_until = x.valid_until;
        }
      })
      this.show_form = true;
    },
    validate(){
      if(!this.form_subsSelected){
        if(!this.form_valid_from || !this.form_valid_until){
          this.makeToast('danger', this.$t('Error'), 'Complete Date fields');
          return false
        }
      }
      if(this.form_valid_from > this.form_valid_until){
        this.makeToast('danger', this.$t('Error'), 'Valid until should be after valid from');
        return false
      }
      if(!this.form_plan){
        this.makeToast('danger', this.$t('Error'), 'Complete Payment method / Partner field.');
        return false
      }
      return true
    },
    async submitChanges(){
      if(this.validate()){
        
        let data = {
          'tab': 'subscription',
          'payment_id': this.form_id,
          'user_id': this.userData.id,
          'payment_method_id':this.form_plan,
          'subs_is_free':this.form_subsSelected,
          'valid_from': this.form_valid_from,
          'valid_until': this.form_valid_until
        }
        await this.$http.post(editLicence, data).then( response => {
          if(response.data.status == 200){
            this.$emit('refetch-data');
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));

            this.closeForm();
          } else {
            this.makeToast('danger', this.$t('Error'), response.data.msg);
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
      }

    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
